import axios from '@/axios/globalaxios'

// 查询用户管理 信息
export const findGroupByClassId = data =>{
    return axios({
        url: "/tradelab/course/findGroupByClassId",
        method: 'post',
        data:data
    })
}


//清算
//查询所有的课程名，id
export const findAllGroupName = data => { 
    return axios ({
        url: "/tradelab/Reliquidate/findAllGroupName",
        method: 'post',
        data: data,
    })
}
//查询所有的大赛名，id
export const findAllCourseName = data => {
    return axios ({
        url: "/tradelab/Reliquidate/findAllCourseName",
        method: 'post',
        data: data,
    })
}
// 课程下 获取清算 acid
export const findAcidByCourseID = data =>{
    return axios({
        url: "/tradelab/Reliquidate/findAcidByCourseID",
        method: 'post',
        data:data
    })
}
// 大赛下 获取清算 acid
export const findAllAcidByCompetitionID = data =>{
    return axios({
        url: "/tradelab/Reliquidate/findAllAcidByCompetitionID",
        method: 'post',
        data:data
    })
}
// 清算 acid
export const dealreliquidate = data =>{
    return axios({
        url: "/tradelab/Reliquidate/dealreliquidate",
        method: 'post',
        data:data
    })
}
// 修改 进入
export const findList = data =>{
    return axios({
        url: "/tradelab/Reliquidate/findList",
        method: 'post',
        data:data
    })
}
export const updateEntrust = data =>{
    return axios({
        url: "/tradelab/Reliquidate/updateEntrust",
        method: 'post',
        data:data
    })
}